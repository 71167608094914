import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';


function ModalAnnouncement({ onClose, visible }) {

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor='#F0EDE6'>
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor='black'
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          What is an Announcement Link?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily='Newsreader' margin="16px 0" color="black">
            <b>Announcement Link</b> is an announcement website aimed at people who were not directly invited, so the feature content in it is not complete. On this announcement website, <b>there are no</b> reception event details (only the religious ceremony), RSVP feature, event reminder button, view map button, and so on.
          </Text>          
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalAnnouncement.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};


export default ModalAnnouncement;