import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';


function ModalInvitation({ onClose, visible }) {

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor='#F0EDE6'>
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor='black'
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"          
        >
          What is an Invitation Link?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily='Newsreader' margin="16px 0" color="black">
            <b>Invitation Link</b> is a website with the most complete information, where this link is aimed at invited guests who wish to attend the event directly.
          </Text>          
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalInvitation.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};


export default ModalInvitation;