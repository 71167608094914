import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalJenisUndangan({ onClose, visible }) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor="#F0EDE6">
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor="black"
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          What is the Type of Invitation?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily="Newsreader" margin="16px 0" color="black">
            <b>- Personal Invitation</b>
            <br /> A website invitation link which is <b>addressed to personal or individual guests</b>
            <br />
            Example: Mr. Anton
            <br />
            <br />
            RSVP on the personal invitation link{' '}
            <b>an only provide confirmation from the person/invitee concerned.</b>
            <br />
            <br />
            <b>- Group Invitation </b>
            <br />
            A website invitation link which is <b>addressed to a group of people.</b>
            <br />
            Example: Alumni of Harvard University
            <br />
            <br />
            RSVP on the group invitation link can be used by each member of the group concerned. 
            After RSVP, all members will get their own personal link.
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalJenisUndangan.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

export default ModalJenisUndangan;
